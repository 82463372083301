import { useField } from '@rocketseat/unform';
import bemCn from 'bem-cn';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import SniffFormError from '../sniff-form-error';
import 'react-phone-input-2/lib/style.css';
import './style.scss';

const b = bemCn('sniff-phone-input');

function parseValue(inputRef) {
    return inputRef.props.value.replace(/\D/g, '');
}

const SniffPhoneInput = ({ name, placeholder, inputRef, autoFocus, ...rest }) => {
    const ref = inputRef || useRef();
    const { fieldName, error, registerField, defaultValue } = useField(name);
    const [value, setValue] = useState(defaultValue);

    const number = useMemo(() => (value == null ? '' : value), [value]);

    const handleChange = (value) => {
        setValue(value);
    };

    useEffect(() => {
        if (ref?.current) {
            registerField({
                name: fieldName,
                ref: ref.current,
                parseValue,
                path: 'props.value',
            });
            setValue(defaultValue);
        }
    }, [ref.current, fieldName]); // eslint-disable-line

    return (
        <>
            <ReactPhoneInput
                containerClass={b.mix('react-tel-input')}
                inputClass={b('input')}
                inputProps={{ autoFocus }}
                placeholder={placeholder}
                searchPlaceholder={placeholder}
                country={'us'}
                onChange={handleChange}
                value={number}
                ref={ref}
                {...rest}
            />
            <SniffFormError error={error} />
        </>
    );
};

export default SniffPhoneInput;
