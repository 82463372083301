import React from 'react';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import HeaderLogoWrapper from '../header-logo-wrapper/index-ssr';
import RenderHostButton from './render-host-button';
import SpotShareButton from '../spot-share-button/share-ssr';
import SpotFavFab from '../SpotFavFab/fav-ssr';

const RenderMobileDefaultRightSSR = ({
    navigationConfig,
    toggleMenu,
    isMenuOpened,
    isFixed,
    isAuthenticated,
    isMobile,
    useState,
    userDetails,
    useEffect,
    isSpotPage,
    spot,
    useGetShareLink,
    useCallback,
    useMutateFavorites,
    useGetFavorites,
    isBlog,
    isBlogSub,
    isBarSignupTest,
}) => {
    const { favSpots } = userDetails?.id ? useGetFavorites() : { favSpots: [] };
    const canShowHostBtn = userDetails?.id && (isBlog || isBlogSub);
    const hasSpots = userDetails && userDetails.spots?.length > 0;
    const hasSpotNotLive = userDetails?.spots?.length === 1 && !userDetails.spots[0].publishedAt;

    return (
        <div className="navigation-guest__right">
            {canShowHostBtn && <RenderHostButton {...{ isMobileSSR: isMobile, hasSpots, hasSpotNotLive, SSR: !!useState }} />}
            {isSpotPage ? (
                <div className="nav-action">
                    <SpotShareButton
                        media={spot.spotPhotos.length !== 0 && spot.spotPhotos[0].imageAws}
                        {...{ spot, useState, isMobile, useGetShareLink }}
                    />
                    <SpotFavFab src={SIGN_UP_SRC.FAVORITE_SPOT_WEB} {...{ spot, favSpots, userDetails, useState, useCallback, useMutateFavorites }} />
                    {!isAuthenticated && (
                        <HeaderLogoWrapper
                            {...{ toggleMenu, isMenuOpened, isAuthenticated: false, useState, useEffect, isFixed, isSpotPage, isBarSignupTest }}
                            isMobileSSR={isMobile}
                        />
                    )}
                </div>
            ) : (
                <HeaderLogoWrapper
                    config={navigationConfig}
                    isFixed={isFixed}
                    isMobileSSR={isMobile}
                    useState={useState}
                    useEffect={useEffect}
                    toggleMenu={toggleMenu}
                    userDetails={userDetails}
                    isMenuOpened={isMenuOpened}
                    isAuthenticated={isAuthenticated}
                />
            )}
        </div>
    );
};

export default RenderMobileDefaultRightSSR;
