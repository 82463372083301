import { useCallback, useEffect, useState } from 'react';

export const MOBILE_SCREEN = '(min-width: 0px) and (max-width: 768px)';

export default function useMobileScreen() {
    const [isMobile, setIsMobile] = useState(true);

    const handleResize = useCallback((e) => {
        const window = e.currentTarget;
        const newWidth = window.matchMedia(MOBILE_SCREEN).matches;
        if (isMobile !== newWidth) {
            setIsMobile(newWidth);
        }
    });

    useEffect(() => {
        setIsMobile(window.matchMedia(MOBILE_SCREEN).matches);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return isMobile;
}
