import React from 'react';
import { RouteFormatter } from '../../routes';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import SniffButton from '../sniff-button';

const RenderHostButton = ({ history, hasSpotNotLive, hasSpots, isMobileSSR, SSR }) => {
    const isMobile = SSR ? isMobileSSR : useMobileScreen();

    const handleClick = () => {
        if (SSR) {
            window.location = RouteFormatter.hostAccount();
            return;
        }
        history.push(RouteFormatter.hostAccount());
    };

    if (hasSpotNotLive || !hasSpots) {
        return (
            <a href={RouteFormatter.hostLanding()} className="host-button">
                <SniffButton color="secondary" size={isMobile ? 'sm' : 'md'}>
                    Host
                </SniffButton>
            </a>
        );
    }
    return (
        <SniffButton color="secondary" className="host-button" size={isMobile ? 'sm' : 'md'} onClick={handleClick}>
            Host
        </SniffButton>
    );
};

export default RenderHostButton;
