/* eslint-disable react/display-name */
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NavigationSSR from '../../../../components/navigation-guest/navigation-ssr';
import { useGetFavorites, useMutateFavorites } from '../../hooks/useFavorites';
import useGetShareLink from '../../hooks/useGetShareLink';
import useMobileScreen from '../../hooks/useMobileScreen';

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

const NavBar =
    ({ userDetails, isSpotPage, spot, isHostLanding }, { pathname }) =>
    () => {
        const isMobileSSR = useMobileScreen();

        if (isHostLanding) {
            return '';
        }

        return (
            <ApolloProvider client={client}>
                <NavigationSSR
                    {...{
                        userDetails,
                        isSpotPage,
                        spot,
                        pathname,
                        isMobileSSR,
                        useEffect,
                        useState,
                        useMemo,
                        useGetShareLink,
                        useCallback,
                        useMutateFavorites,
                        useGetFavorites,
                    }}
                />
            </ApolloProvider>
        );
    };

export default NavBar;
