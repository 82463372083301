import React from 'react';
import { RouteFormatter } from '../../routes';
import { logOut } from '../../helpers/log-out';
import SvgAddCircle from 'src/assets/svg/SvgAddCircle.svg';
import SvgAmenitiesDefault from 'src/assets/svg/amenities/default.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';
import SvgHandshake from 'src/assets/svg/SvgHandshake.svg';
import SvgHeart from 'src/assets/svg/SvgHeart.svg';
import SvgHostMoney from 'src/assets/svg/SvgHostMoney.svg';
import SvgHostPets from 'src/assets/svg/SvgHostPets.svg';
import SvgMessageBubble from 'src/assets/svg/SvgMessageBubble.svg';
import SvgSearch from 'src/assets/svg/SvgSearch.svg';
import SvgSubs from 'src/assets/svg/SvgSubs.svg';
import SvgSystemUser from 'src/assets/svg/SvgSystemUser.svg';

export const NAV_ALT = 'SNIFFSPOT';
export const ACCOUNT_LOGO_ALT = 'Profile ';

const SEPARATOR = { type: 'separator' };

export const NAV_ITEMS = {
    LOGIN: {
        title: 'Log in',
        icon: <SvgDog width={20} height={20} />,
        url: RouteFormatter.signIn(),
        type: 'internal',
    },
    VISIT_LIST: {
        title: 'Visits',
        icon: <SvgAmenitiesDefault width={20} height={20} />,
        url: RouteFormatter.visitList(),
    },
    SUBSCRIPTIONS: {
        title: 'Subscriptions',
        icon: <SvgSubs width={20} height={20} />,
        url: RouteFormatter.subscriptions(),
    },
    MESSAGES: {
        title: 'Messages',
        icon: <SvgMessageBubble width={20} height={20} />,
        id: 'messages',
        url: RouteFormatter.hostMessages(),
    },
    GUEST_MESSAGES: {
        title: 'Messages',
        icon: <SvgMessageBubble width={20} height={20} />,
        id: 'messages',
        url: RouteFormatter.guestMessages(),
    },
    ACCOUNT: { title: 'Account', icon: <SvgSystemUser width={20} height={20} />, url: RouteFormatter.guestAccount() },
    ACCOUNT_HOST_EDIT: {
        title: 'My profile',
        icon: <SvgSystemUser width={20} height={20} />,
        url: RouteFormatter.hostAccountEdit(),
    },
    EXPLORE_SPOTS: {
        title: 'Explore spots',
        icon: <SvgSearch width={20} height={20} />,
        url: RouteFormatter.listings({}),
    },
    INVITE_FRIENDS: {
        title: 'Invite friends',
        icon: <SvgHandshake width={20} height={20} />,
        url: RouteFormatter.invite(),
    },
    FAVORITES: { title: 'My favorites', icon: <SvgHeart width={20} height={20} />, url: RouteFormatter.favorites() },
    PAYMENTS: { title: 'Payments', url: RouteFormatter.payments() },
    HOST_WITH_SNIFF: {
        title: 'Host with Sniffspot',
        icon: <SvgHostPets width={20} height={20} />,
        url: RouteFormatter.hostLanding(),
        type: 'internal',
    },
    SWITCH_TO_HOST: {
        title: 'Switch to host mode',
        icon: <SvgHostPets width={20} height={20} />,
        url: RouteFormatter.hostAccount(),
    },
    SWITCH_TO_GUEST: { title: 'Switch to guest mode', url: RouteFormatter.home(), type: 'switch_guest' },
    SEPARATOR,
    MY_EARNINGS: {
        title: 'My earnings',
        id: 'earnings',
        sub: 'Setup required',
        subClass: 'warning',
        icon: <SvgHostMoney width={20} height={20} />,
        url: RouteFormatter.earnings(),
    },
    ADD_SPOT: {
        title: 'Add a spot',
        icon: <SvgAddCircle width={20} height={20} />,
        url: RouteFormatter.newSpot(),
        type: 'internal',
    },
    TRUST_SAFETY: { title: 'Trust & Safety', url: RouteFormatter.web.trust(), type: 'external', blank: true },
    HELP_CENTER: { title: 'Help Center', url: RouteFormatter.helpCenter.root(), type: 'external', blank: true },
    JOBS: { title: 'Jobs', url: RouteFormatter.jobs(), type: 'external' },
    BLOG: { title: 'Blog', url: RouteFormatter.blog(), type: 'external', blank: true },
    SIGN_OUT: {
        title: 'Sign out',
        url: RouteFormatter.signOut(),
        id: 'signout',
        onClick: logOut,
    },
};
