import { RouteFormatter } from '../routes';
import CookieService from '../services/cookie.service';

export const logOut = (e) => {
    e.preventDefault();

    CookieService.erase('filters');
    CookieService.erase('user');
    CookieService.erase('subscribe');
    CookieService.erase('__profilin');

    fetch(RouteFormatter.signOut(), {
        headers: {
            Accept: 'text/html',
            'Content-Type': 'text/html',
        },
        credentials: 'include',
    }).then(() => window.location.replace('/'));
};
