import React from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('sniff-form-error');

const SniffFormError = ({ error, className }) => {
    if (!error) {
        return null;
    }
    return <span className={b.mix(`${className} snif-s2 snif-medium text-error mt-1 mb-1`)}>This is required</span>;
};

export default SniffFormError;
