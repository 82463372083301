import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { PLATFORM } from '../../consts/platform.consts';
import CookieService from '../../services/cookie.service';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { checkPlatform, smartBannerShow } from '../../utils';
import { appleStoreLink, googlePlayLinkUrl } from '../../consts/link.consts';
import appLogoImg from '../../assets/img/sniffspot-app-store-logo.png';
import PictureSet from '../picture-set';
import Rating from '../rating';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './style.scss';

const b = bemCn('sniff-smart-banner');

const SmartBannerServer = () => '';

const SmartBannerClient = () => {
    const [hasCookie, setHasCookie] = useState(CookieService.get(COOKIE_PARAM_NAME.SNIFF_APP_DOWNLOAD_BANNER));
    const canShowSmartBanner = smartBannerShow();
    const isApple = checkPlatform() === PLATFORM.IOS;
    const appLink = isApple ? appleStoreLink : googlePlayLinkUrl;

    const closeBanner = () => {
        CookieService.set(COOKIE_PARAM_NAME.SNIFF_APP_DOWNLOAD_BANNER, 'close', { expires: 30 });
        setHasCookie(true);
    };

    if (!canShowSmartBanner || hasCookie) {
        return '';
    }

    return (
        <div className={b()}>
            <div className={b('left')}>
                <div className={b('left_close')}>
                    <div onClick={closeBanner}>
                        <SvgCross2 />
                    </div>
                    <PictureSet imgs={appLogoImg} width={56} height={56} alt="Sniffspot app store logo" />
                </div>
                <div className={b('left_content')}>
                    <p>Sniffspot</p>
                    <p>Rent safe & private dog parks</p>
                    <div>
                        <Rating value={5} showNmb={false} />
                        4.9 • 12.6K Ratings
                    </div>
                </div>
            </div>
            <a href={appLink} className={b('right')} target="_blank" rel="noopener noreferrer">
                <div>GET</div>
            </a>
        </div>
    );
};

const SmartBanner = () => {
    const [SmartBannerComp, setSmartBannerComp] = useState(() => SmartBannerServer);

    useEffect(() => {
        setSmartBannerComp(() => SmartBannerClient);
    }, []);

    const SmartBannerC = <SmartBannerComp />;

    return SmartBannerC;
};

export default SmartBanner;
