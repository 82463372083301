import React from 'react';
import bemCn from 'bem-cn';
import { NAV_ALT } from '../navigation/navigation.consts';
import { RouteFormatter } from '../../routes';
import PictureSet from '../picture-set';
import logoMin from '../../assets/img/sniffspot-logo-min.png';
import logo from '../../assets/img/sniffspot-logo-app.png';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import './style.scss';

const b = bemCn('navigation-logo');

const NavigationLogoSSR = (props = {}) => {
    const { className = '', full, isApp, isMobile, useState, isSpotPage } = props;
    const src = isMobile ? logoMin : logo;
    const width = isMobile ? 50 : 183;
    const height = isMobile ? 34 : 34;
    const spotTitle = isSpotPage ? props.spot?.title : '';

    const handleBack = () => window.location.replace(RouteFormatter.listings({}));

    if (isMobile && isSpotPage) {
        return (
            <>
                <div className={b('back-btn')} onClick={handleBack}>
                    <SvgChevronLeft />
                </div>
                <div className="nav-title snif-s1 snif-medium spot-detail" style={{ textTransform: 'capitalize' }}>
                    {spotTitle}
                </div>
            </>
        );
    }

    return (
        <a href={RouteFormatter.home()} className={className}>
            {full && !isApp ? (
                <SvgSnifLogo height={32} className="ms-2" />
            ) : (
                <PictureSet
                    imgs={src}
                    SSR={true}
                    useState={useState}
                    alt={NAV_ALT}
                    className={b('img')}
                    width={width}
                    height={height}
                    method="eager"
                />
            )}
        </a>
    );
};

export default NavigationLogoSSR;
