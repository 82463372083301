import { __RouterContext } from 'react-router';
import queryString from 'query-string';
import { useContext } from 'react';

export default function useParams() {
    const context = useContext(__RouterContext);
    return {
        ...queryString.parse(context.location.search),
        ...context.match.params,
    };
}
