import React, { useEffect, useRef, useState } from 'react';
import sha256 from 'crypto-js/sha256';
import {
    FACEBOOK_PIXEl_ID,
    GOOGLE_ADS_CODE,
    GOOGLE_ADS_HOST_SIGN_IN,
    NEXTDOOR_ADS_CODE,
    REDDIT_PIXEL_ID,
    TIKTOK_PIXEL_ID,
} from '../../consts/env.consts';
import { sleep } from '../../helpers/common';
import useParams from '../../services/hooks/useParams';
import CookieService from '../../services/cookie.service';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import SniffPhoneInput from '../../components/common/sniff-phone-input';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { validateEmail } from '../../utils';
import { RouteFormatter } from '../../routes';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import useGetProbabilityTest from '../../services/hooks/useGetProbabilityTest';
import { getAndUpdateABTest } from '../../helpers/abTest';
import { toasterDanger as toasterDangerC } from '../../components/sniff-toast';
import SniffButton from '../../components/sniff-button';
import SniffInput from '../../components/sniff-input';
import SvgHostFacebook from 'src/assets/svg/SvgHostFacebook.svg';
import SvgHostGoogle from 'src/assets/svg/SvgHostGoogle.svg';
import SvgHostApple from 'src/assets/svg/SvgHostApple.svg';
import SvgHostEmail from 'src/assets/svg/SvgHostEmail.svg';
import SvgLoading from 'src/assets/svg/SvgLoading.svg';
import './render-sign-up-email.scss';

const useMutateHostSignupC = require('../../services/hooks/useMutateHostSignup');

const RenderSignUpEmail = ({
    b,
    src,
    reff,
    from,
    isFocused,
    setIsFocused,
    showSubText,
    useStateSSR,
    signUpHost,
    signUpHostShort,
    signUp,
    toasterDanger: toasterDangerSSR,
    useMutateHostSignup: useMutateHostSignupSSR,
    adPop,
    seoPopup,
    setFinished,
    setCouponOpen,
    hideSocialOptions,
    phoneSignTest,
}) => {
    const params = useStateSSR ? {} : useParams();
    const useMutateHostSignup = useMutateHostSignupSSR || useMutateHostSignupC.default;
    const isMobile = useMobileScreen();
    const toasterDanger = toasterDangerSSR || toasterDangerC;
    const { mutateHostSignup, signUpError } = useMutateHostSignup();
    const { getProbTest } = useGetProbabilityTest();
    const refEmail = useRef(null);
    const refPhone = useRef(null);
    const refLastName = useRef(null);
    const refPassword = useRef(null);
    const refFirstName = useRef(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const toastPosition = isMobile ? 'top-right' : 'bottom-right';
    const canShowEmail = (!isMobile && isFocused) || ((signUp || seoPopup) && isFocused);
    const canShowOptions = ((isMobile && !adPop) || (!isMobile && !isFocused) || (adPop && isFocused)) && !seoPopup && !hideSocialOptions;
    const canShowCTA = ((adPop || seoPopup) && isFocused) || (!adPop && !seoPopup);
    const invitedAs = seoPopup || (signUp && !signUpHost) ? 'PAID' : 'HOST';
    const createdFrom = src || CookieService.get(COOKIE_PARAM_NAME.SIGN_UP_FROM);
    const addedQueries = `${invitedAs == 'HOST' ? 'kind=host&' : ''}${
        reff ? `kind=paid&invited_by_uuid=${params?.uuid}&` : ''
    }created_platform=web&created_from=${createdFrom?.toLowerCase()}`;

    const validate = () => {
        let title = '';
        let sub = '';

        if (!validateEmail(email)) {
            title = 'Email address incorrect';
            sub = 'Please re-enter your correct email address';
            toasterDanger({ title, sub, addedOpt: { position: toastPosition } });
            refEmail.current.focus();
            return false;
        }
        if (!firstName.trim()) {
            title = 'First name incorrect';
            sub = 'Please re-enter your first name using alphabet';
            toasterDanger({ title, sub, addedOpt: { position: toastPosition } });
            refFirstName.current.focus();
            return false;
        }
        if (!lastName.trim()) {
            title = 'Last name incorrect';
            sub = 'Please re-enter your last name using alphabet';
            toasterDanger({ title, sub, addedOpt: { position: toastPosition } });
            refLastName.current.focus();
            return false;
        }
        if (password.trim().length < 6) {
            title = 'Password must be at least 6 chars';
            sub = 'Please re-enter your password, you may use alphanumeric and symbols';
            toasterDanger({ title, sub, addedOpt: { position: toastPosition } });
            refPassword.current.focus();
            return false;
        }
        if ((signUpHost || phoneSignTest) && phone.length < 8) {
            title = 'Cell phone number is required';
            sub = 'Please enter your phone number';
            toasterDanger({ title, sub, addedOpt: { position: toastPosition } });
            refPhone.current.numberInputRef.focus();
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        try {
            setLoading(true);

            if (!validate()) {
                throw 'Validate returns false';
            }

            const res = await mutateHostSignup({
                variables: {
                    email: email.trim(),
                    firstname: firstName.trim(),
                    lastname: lastName.trim(),
                    password: password.trim(),
                    phoneNumber: phone,
                    uuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID),
                    invitedAs,
                    invitedByUuid: params?.uuid,
                    utmSource: params?.utm_source,
                    createdFrom,
                    createdPlatform: 'WEB',
                },
            });
            const newId = res.data.signUp.id;

            if (invitedAs === 'HOST') {
                if (REDDIT_PIXEL_ID && typeof window.rdt !== 'undefined') {
                    window.rdt('track', 'Lead');
                }
                if (FACEBOOK_PIXEl_ID && typeof window.fbq !== 'undefined') {
                    window.fbq('init', FACEBOOK_PIXEl_ID, { extern_id: `user.${newId}` });
                    window.fbq('track', 'Lead', {}, { eventID: `lead.${newId}` });
                }
                if (TIKTOK_PIXEL_ID && typeof window.ttq !== 'undefined') {
                    window.ttq?.track('Contact');
                }
                if (NEXTDOOR_ADS_CODE && typeof window.ndp !== 'undefined') {
                    window.ndp('track', 'CONVERSION');
                }
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'Leadsubmit', {
                    event_category: 'Lead',
                    event_label: 'Leadbutton',
                    event_value: '1',
                });
                window.gtag('event', 'Lead', { event_category: 'User_front' });
                if (GOOGLE_ADS_CODE && GOOGLE_ADS_HOST_SIGN_IN) {
                    window.gtag('event', 'conversion', { send_to: `${GOOGLE_ADS_CODE}/${GOOGLE_ADS_HOST_SIGN_IN}` });
                }
            } else {
                window.gtag('event', 'CompleteRegistration', { event_category: 'User_front' });
                if (FACEBOOK_PIXEl_ID && typeof window.fbq !== 'undefined') {
                    window.fbq('init', FACEBOOK_PIXEl_ID, { extern_id: `user.${newId}` });
                    window.fbq('track', 'CompleteRegistration', {}, { eventID: `complete_registration.${newId}` });
                }
                if (TIKTOK_PIXEL_ID && typeof window.ttq !== 'undefined') {
                    window.ttq?.identify({
                        ...{
                            // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                            email: sha256(res.data.signUp.email).toString(),
                            // A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
                            external_id: sha256(newId).toString(),
                            // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
                        },
                        ...(res.data.signUp.phoneNumber && { phone_number: res.data.signUp.phoneNumber }),
                    });
                    window.ttq?.track('CompleteRegistration', {
                        event_id: sha256(`complete_registration.${newId}`).toString(),
                    });
                }
                if (reff) {
                    window.gtag('event', 'paid_sign_up', { event_category: 'User_front' });
                }
            }

            if (createdFrom === SIGN_UP_SRC.HOST_BANNER_WEB) {
                const resProb = await getProbTest({
                    variables: { tests: [USER_TEST_GROUP.HOST_SPOT_FLOW_PHOTO_COPY2] },
                });
                const prob = resProb.data.probabilities[0].probability;
                await getAndUpdateABTest({
                    user: res.data.signUp,
                    cookie: COOKIE_PARAM_NAME.HOST_SPOT_FLOW2_TEST,
                    userTestGroup: USER_TEST_GROUP.HOST_SPOT_FLOW_PHOTO_COPY2,
                    prob,
                });
            }

            const re_uri = CookieService.get(COOKIE_PARAM_NAME.REDIRECT);
            CookieService.erase(COOKIE_PARAM_NAME.SIGN_UP_FROM);

            if (seoPopup) {
                // open coupons
                setCouponOpen(true);
                setLoading(false);
                setFinished(true);
                CookieService.set(COOKIE_PARAM_NAME.SNIFF_SEO_POPUP_SHOWN_4936, 'true', { expires: 90 });
                return;
            }

            if (signUpHostShort) {
                CookieService.erase(COOKIE_PARAM_NAME.REDIRECT);
                window.location.replace(RouteFormatter.newSpot());
            } else if (src === SIGN_UP_SRC.AD_POPUP_WEB || src === SIGN_UP_SRC.SEARCHPOPUP_WEB) {
                CookieService.erase(COOKIE_PARAM_NAME.REDIRECT);
                window.location.replace(from);
            } else if (re_uri) {
                CookieService.erase(COOKIE_PARAM_NAME.REDIRECT);
                window.location.replace(re_uri);
            } else if (reff) {
                window.location.replace(RouteFormatter.listings({}));
            } else if (signUpHost) {
                window.location.replace(RouteFormatter.newSpot());
            } else if (signUp) {
                window.location.replace(RouteFormatter.home());
            } else {
                window.location.replace(RouteFormatter.newSpot());
            }
            await sleep(1000);
        } catch (error) {
            console.warn(error);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!signUpError) {
            return;
        }

        toasterDanger({
            title: signUpError.graphQLErrors.length ? signUpError.graphQLErrors[0]?.message : signUpError.message,
            addedOpt: { position: toastPosition, autoClose: 3000 },
        });
        refEmail.current.focus();
    }, [signUpError]);

    useEffect(() => {
        if (isFocused) {
            refEmail.current.setAttribute('name', 'email');
            refEmail.current.setAttribute('autocomplete', 'email');
        }
    }, [isFocused]);

    return (
        <div className={b('start-hosting')}>
            <form>
                <input className={b('start-hosting_hdn-inp')} type="tel" size={5} tabIndex={-1} readOnly />
                {canShowEmail && <div className="snif-p snif-medium mb-1">Email address</div>}
                <div className="email-sign-up">
                    <SniffInput
                        refComp={refEmail}
                        type="email"
                        value={email}
                        onFocus={() => setIsFocused(true)}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="yourname@email.com"
                        autoComplete="one-time-code"
                    />
                    <SvgHostEmail />
                </div>
                <div className={isFocused ? '' : 'd-none'}>
                    <div className={`d-flex justify-content-center align-items-center mt-2 ${seoPopup ? 'mrgn-seopopup' : ''}`}>
                        <div className="w-100 me-2">
                            <div className="snif-p snif-medium mb-1">First name</div>
                            <SniffInput
                                refComp={refFirstName}
                                name="firstname"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Andrea"
                            />
                        </div>
                        <div className="w-100">
                            <div className="snif-p snif-medium mb-1">Last name</div>
                            <SniffInput
                                refComp={refLastName}
                                name="lastname"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Appleseed"
                            />
                        </div>
                    </div>
                    {(signUpHost || phoneSignTest) && (
                        <>
                            <div className="snif-p snif-medium mt-2 mb-1">Cell phone</div>
                            <SniffPhoneInput
                                inputRef={refPhone}
                                name="phone-number"
                                value={phone}
                                onChange={(val) => setPhone(val.replace(/\D/g, ''))}
                            />
                        </>
                    )}
                    <div className={`snif-p snif-medium mt-2 mb-1 ${seoPopup ? 'mrgn-seopopup' : ''}`}>Set a password</div>
                    <SniffInput
                        className="mb-1"
                        refComp={refPassword}
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="********"
                    />
                </div>
                {canShowCTA && (
                    <SniffButton
                        type="submit"
                        className={`mt-2 ${seoPopup ? 'mrgn-seopopup' : ''}`}
                        size="lg"
                        disabled={loading}
                        onClick={handleSubmit}
                        block
                    >
                        {signUpHost ? 'Start hosting' : 'Create account'} {loading && <SvgLoading />}
                    </SniffButton>
                )}
            </form>
            {(isFocused || showSubText) && (
                <div className="snif-s2 text-deep text-center mt-2">
                    We'll send you promotions, offers, inspiration and policy updates via email. You can unsubscribe at any time.
                </div>
            )}
            <div className={canShowOptions ? '' : 'd-none'}>
                <div className={`d-flex justify-content-center align-items-center ${adPop ? 'mt-2' : 'mt-3'}`}>
                    <div className="sign-up-border" />
                    <div className="snif-s1 sign-up-text">Or sign up with</div>
                    <div className="sign-up-border" />
                </div>
                <div className={`d-flex justify-content-center align-items-center ${adPop ? 'mt-2 mb-0' : 'mt-3 mb-2 mb-md-0'}`}>
                    <form name="googleForm" action={`/users/auth/google_oauth2?${addedQueries}`} method="post">
                        <input type="hidden" name="authenticity_token" value={document.querySelector('[name=csrf-token]').content} />
                        <SniffButton type="submit" className="btn-google" size="lg" block>
                            <SvgHostGoogle className="sign-icon" />
                        </SniffButton>
                    </form>
                    <form name="facebookForm" action={`/users/auth/facebook?${addedQueries}`} method="post">
                        <input type="hidden" name="authenticity_token" value={document.querySelector('[name=csrf-token]').content} />
                        <SniffButton type="submit" className="btn-facebook" size="lg" block>
                            <SvgHostFacebook className="sign-icon" />
                        </SniffButton>
                    </form>
                    <form name="appleForm" action={`/users/auth/apple?${addedQueries}`} method="post">
                        <input type="hidden" name="authenticity_token" value={document.querySelector('[name=csrf-token]').content} />
                        <SniffButton type="submit" className="btn-apple" size="lg" block>
                            <SvgHostApple className="sign-icon" />
                        </SniffButton>
                    </form>
                </div>
            </div>
            {seoPopup && isFocused && (
                <p className="snif-p snif-semibold text-medium text-center mt-2">
                    I already have an account, <a href={RouteFormatter.signIn()}>Sign in</a>
                </p>
            )}
        </div>
    );
};

export default RenderSignUpEmail;
