import BemCn from 'bem-cn';
import React from 'react';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { apiDomain } from '../../consts/env.consts';
import { RouteFormatter } from '../../routes';
import CookieService from '../../services/cookie.service';
import SvgHeart from 'src/assets/svg/SvgHeart.svg';
import SvgHeartEmpty from 'src/assets/svg/SvgHeartEmpty.svg';
import SvgHeartFull from 'src/assets/svg/SvgHeartFull.svg';
import './index.scss';

const b = BemCn('spot-fav-fab');

const SpotFavFab = ({ spot, useState, favSpots: favorites, userDetails: user, useCallback, useMutateFavorites, iconSize = 24, listing, src }) => {
    const [favorite, setFavorite] = useState(favorites?.some((item) => item.id == spot.id));
    const { toggleFavSpot } = useMutateFavorites(favorite);

    const toggleFav = async (spot) => {
        await toggleFavSpot({ variables: { id: spot.id } });
        setFavorite(!favorite);
    };

    const toggleFavorite = useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            if (user?.id) {
                toggleFav(spot);
            } else {
                CookieService.set(COOKIE_PARAM_NAME.REDIRECT, apiDomain + window.location.pathname + window.location.search);
                src && CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, src, { expires: 1 });
                window.location.pathname = RouteFormatter.signUp();
            }
        },
        [spot, toggleFav, user]
    );

    return (
        <span className={b('fab')} onClick={toggleFavorite}>
            {favorite ? (
                <SvgHeartFull width={iconSize} height={iconSize} />
            ) : listing ? (
                <SvgHeartEmpty width={iconSize} height={iconSize} />
            ) : (
                <SvgHeart width={iconSize} height={iconSize} />
            )}
        </span>
    );
};

export default SpotFavFab;
