import { gql, useLazyQuery } from '@apollo/client';

const SHARE_SPOT_LINK = gql`
    query shareSpotLink(
        $id: ID!
        $campaign: String
        $content: String
        $source: String
        $medium: String
        $prefix: String
        $asHost: Boolean
    ) {
        shareSpotLink(
            id: $id
            campaign: $campaign
            content: $content
            source: $source
            medium: $medium
            prefix: $prefix
            asHost: $asHost
        )
    }
`;

export default function useGetShareLink() {
    const [getShareLink, { data, loading }] = useLazyQuery(SHARE_SPOT_LINK);
    return { getShareLink, shareSpotLink: data?.shareSpotLink, loading };
}
